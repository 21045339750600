<template>
  <b-modal centered id="communityClusterDetailModal" ref="communityClusterDetailModal" :title="`${$t('COMMUNITY.TITOLO')}: ${location}`" size="lg" hide-footer>
    <div class="row">
      <div
        v-for="item in users"
        :key="item.id"
        class="col-6 col-md-3 mb-5"
      >
        <CommunityUserCard :user="item" :from-modal="true"></CommunityUserCard>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
@import "~bootstrap-vue/dist/bootstrap-vue.css";
</style>

<script>
import CommunityUserCard from "./CommunityUserCard";

export default {
  name: "CommunityClusterDetailModal",
  components: {
    CommunityUserCard,
  },
  props: ["users"],
  data() {
    return {
      location: "",
    }
  },
  watch: {
    users() {
      if(this.users) {
        this.location = this.users[0].residenceCity;
      }
    }
  },
  methods: {
    close() {
      this.$refs["communityClusterDetailModal"].hide();
    },
    open() {
      this.$refs["communityClusterDetailModal"].show();
    },
  }
};
</script>
