<template>
  <div class="card card-custom card-stretch cursor-pointer" :style="'border-color:' + primaryColor + ' !important;'" :class="{'card-border': this.groupId.includes(this.id) || (this.id == 0 && this.groupId.length == 0), 'community': this.id == 0}" @click="selectGroup">
    <div class="card-body text-center p-3">

      <div class="row h-100">
        <!--logo-->
        <div class="col-12">
          <img :src="logo" class="rounded-logo"/>
        </div>
        <!--end::logo-->

        <!--Name-->
        <div class="mt-3 font-weight-bolder font-size-h5 text-center col-12" :class="{'text-dark-75': this.id > 0, 'text-dark-50': this.id == 0}">
          <span>{{name}}</span>
        </div>
        <!--end::Name-->

        <!--Info-->
        <div v-if="id > 0" class="mt-3 text-dark-50 font-weight-bolder text-center col-12 align-self-end">
          <span>{{$t("PARTIALSCOMMUNITY.ISCRITTI", {subscribers: totalSubscribers, profiles: totalProfiles})}}</span>
        </div>
        <!--end::Info-->
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "CommunityFilterGroups",
  props: ["group", "groupId"],
  data() {
    return {
      id: 0,
      logo: this.$imageOptions.defaultGroupUrl,
      name: '',
      totalSubscribers: 0,
      totalProfiles: 0,
      primaryColor: "",
    }
  },

  created() {
    this.id = this.group?.id ?? 0;
    this.logo = this.group?.logo?.uri ?? this.$imageOptions.defaultGroupUrl;
    this.name = this.group?.name ?? this.$t("PARTIALSCOMMUNITY.COMMUNITY");
    this.totalSubscribers = this.group?.countUsers ?? 0;
    this.totalProfiles = this.group?.countUsersProfilePublic ?? 0;
    this.primaryColor = localStorage.getItem("organization") ? JSON.parse(localStorage.getItem("organization"))?.currentOrganization?.data?.primaryColor : "";
  },

  methods: {
    selectGroup() {
      this.$emit('selectGroup', this.id);
    }
  }
};
</script>

<style scoped>
  .rounded-logo {
    height: 95px;
    width: 100%;
    object-fit: cover;
    border-radius: 0.42rem;
  }
  .community {
    background-color: #b5b5b53d !important;
  }

  .card-border {
    border-width: 3px !important;
    /*border-color: #3699ff !important;*/
  }
</style>
