<template>
  <div class="row h-100 justify-content-center">
    <div class="col-lg-10 col-xl-10 justify-content-start">
      <div class="d-flex flex-column px-7 mt-15">
        <div class="row align-items-center">
          <div class="col-12 d-flex justify-content-start">
            <div class="d-flex flex-column">
              <h2 class="card-label h1 font-weight-bolder">
                {{ $t("COMMUNITY.TITOLO") }}
              </h2>
              <h3 class="mt-2">{{ $t("COMMUNITY.SOTTOTITOLO") }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column px-6">
        <!--Search-->
        <form method="get" class="quick-search-form">
          <div class="input-group">
            <div class="input-group-prepend">
              <button
                class="input-group-text line-height-0 p-0 border-0 bg-transparent"
                v-on:click="getSearchData($event)"
              >
                <img src="/assets/media/bipart/ico-search.png" class="w-100" />
              </button>
            </div>

            <input
              type="text"
              class="form-control bg-transparent py-10 h4 border-0"
              :placeholder="$t('COMMUNITY.CERCA')"
              v-model="keywords"
            />

            <div class="input-group-append d-flex flex-wrap">
              <span
                class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0"
              >
                <span class="text-muted h6 mr-4"
                  >{{ $t("COMMUNITY.ISCRITTI") }}:&nbsp;</span
                >{{ totalSubscribers }}
              </span>

              <span
                class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0"
              >
                <span class="text-muted h6 mr-4"
                  >{{ $t("COMMUNITY.PROFILI") }}:&nbsp;</span
                >{{ totalProfiles }}
              </span>

              <span
                v-if="totalGroups"
                class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0"
              >
                <span class="text-muted h6 mr-4"
                  >{{ $t("COMMUNITY.GRUPPI") }}:&nbsp;</span
                >{{ totalGroups }}
              </span>

              <span
                class="input-group-text bg-transparent ml-auto"
                v-if="displayViewMap"
              >
                <button type="button" @click="setView('grid')" id="pop-grid">
                  <i class="fas fa-th text-primary"></i>
                </button>
              </span>
              <span
                class="input-group-text bg-transparent"
                v-if="displayViewMap"
              >
                <button type="button" @click="setView('map')" id="pop-map">
                  <i class="fas fa-map text-primary"></i>
                </button>
              </span>
            </div>
          </div>
        </form>
        <!--end::Search-->

        <!--Groups-->
        <div v-if="totalGroups > 0" class="mt-3">
          <div class="row">
            <div class="col-6 col-md-2">
              <CommunityFilterGroups
                :group-id="groupId"
                @selectGroup="selectGroup($event)"
              ></CommunityFilterGroups>
            </div>

            <div class="col-6 col-md-10">
              <div class="container-fluid mx-0 px-0">
                <div
                  id="communityFilterGroupsContainer"
                  class="row flex-row flex-nowrap overflow-auto"
                >
                  <div
                    v-for="item in groups"
                    :key="item.id"
                    class="col-12 col-md-3 mb-5"
                  >
                    <CommunityFilterGroups
                      :group="item"
                      :group-id="groupId"
                      @selectGroup="selectGroup($event)"
                    ></CommunityFilterGroups>
                  </div>
                </div>

                <div
                  class="row flex-row flex-nowrap overflow-auto font-weight-bold"
                >
                  <div class="col-4">
                    <i
                      class="fas fa-chevron-left text-primary cursor-pointer"
                      v-on:mousedown="buttonLeftFunction"
                      v-on:mouseup="buttonFunctionStop"
                      v-on:touchstart="buttonLeftFunction"
                      v-on:touchend="buttonFunctionStop"
                      v-on:touchcancel="buttonFunctionStop"
                    ></i>
                  </div>

                  <div class="col-4 text-center text-primary">
                    {{ totalGroups + " " + $t("COMMUNITY.GRUPPI") }}
                  </div>

                  <div class="col-4 text-right">
                    <i
                      class="fas fa-chevron-right text-primary cursor-pointer"
                      v-on:mousedown="buttonRightFunction"
                      v-on:mouseup="buttonFunctionStop"
                      v-on:touchstart="buttonRightFunction"
                      v-on:touchend="buttonFunctionStop"
                      v-on:touchcancel="buttonFunctionStop"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Groups-->

        <hr />

        <!--List-->
        <div v-if="selectedView == 'grid'">
          <div class="mt-8">
            <div class="row">
              <div
                v-for="item in community"
                :key="item.id"
                class="col-6 col-md-2 mb-5"
              >
                <CommunityUserCard :user="item"></CommunityUserCard>
              </div>
            </div>
          </div>

          <infinite-loading
            @infinite="loadMore"
            spinner="bubbles"
            v-if="this.currentPage < this.pageCount"
          ></infinite-loading>
          <!--end::List-->
        </div>
        <div v-else class="mb-5">
          <!-- @dragend="handleDrag" -->
          <GmapMap
            :center="myCoordinates"
            :zoom="2"
            ref="mapRef"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: false,
              minZoom: 2,
              maxZoom: maxZoom,
            }"
            style="width: 100%; height: 600px"
          >
            <GmapCluster
              :gridSize="30"
              :zoomOnClick="true"
              @click="selectCluster"
            >
              <GmapMarker
                :key="index"
                v-for="(marker, index) in markers"
                :position="marker.position"
                :clickable="true"
                @click="toggleInfoWindow(marker)"
                :options="{ id: marker.user.id }"
              />

              <GmapInfoWindow
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen = false"
              >
                <CommunityUserCard
                  v-if="infoUser"
                  :user="infoUser"
                  :from-map="true"
                ></CommunityUserCard>
              </GmapInfoWindow>
            </GmapCluster>
          </GmapMap>
        </div>
      </div>
    </div>
    <CommunityClusterDetailModal
      :users="clusterUsers"
      ref="communityClusterDetailModal"
    ></CommunityClusterDetailModal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import InfiniteLoading from "vue-infinite-loading";
import CommunityUserCard from "./partials/CommunityUserCard";
import CommunityFilterGroups from "./partials/CommunityFilterGroups";
import { globalMixin } from "@/mixins/global";
import GmapCluster from "gmap-vue/dist/components/cluster";
import CommunityClusterDetailModal from "./partials/CommunityClusterDetailModal.vue";

export default {
  name: "Community",
  mixins: [globalMixin],
  components: {
    InfiniteLoading,
    CommunityUserCard,
    CommunityFilterGroups,
    GmapCluster,
    CommunityClusterDetailModal,
  },
  data() {
    return {
      keywords: "",
      totalSubscribers: 0,
      totalProfiles: 0,
      totalGroups: 0,
      community: [],
      groups: [],
      currentPage: 1,
      pageCount: 0,
      groupId: [],
      buttonStatus: false,
      buttonLeftStatus: false,
      displayViewMap: true,
      selectedView: "grid",

      myCoordinates: {
        lat: 0,
        lng: 0,
      },
      markers: [],
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWindowPos: null,
      infoUser: null,
      infoWinOpen: false,
      clusterUsers: [],
      maxZoom: 12,
    };
  },
  static: {
    visibleItemsPerPageCount: 12,
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("COMMUNITY.TITOLO") },
    ]);
    await this.getFilterGroups();
    await this.getCommunity();
    await this.getProfiles();
    await this.getSubscribers();
  },

  watch: {
    groupId() {
      this.getCommunity();
    },
  },

  methods: {
    getSearchData(event) {
      event.preventDefault();
      this.getCommunity();
    },

    buttonRightFunction() {
      if (!this.buttonStatus) {
        this.buttonStatus = setInterval(
          () =>
            (document.getElementById(
              "communityFilterGroupsContainer"
            ).scrollLeft += 50),
          30
        );
      }
    },

    buttonLeftFunction() {
      if (!this.buttonStatus) {
        this.buttonStatus = setInterval(
          () =>
            (document.getElementById(
              "communityFilterGroupsContainer"
            ).scrollLeft -= 50),
          30
        );
      }
    },

    buttonFunctionStop() {
      clearInterval(this.buttonStatus);
      this.buttonStatus = false;
    },

    getSubscribers() {
      return new Promise((resolve, reject) => {
        this.$isLoading(true);
        var endpoint = this.generateUrl("users/count", false);
        ApiService.get(this.$apiResource, endpoint)
          .then((res) => {
            this.totalSubscribers = res?.data ?? 0;
            this.$isLoading(false);
            resolve(1);
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },

    getProfiles() {
      return new Promise((resolve, reject) => {
        this.$isLoading(true);
        let qs = "?profilePublic.equals=true";

        var endpoint = this.generateUrl("users/count", false);
        ApiService.get(this.$apiResource, endpoint + qs)
          .then((res) => {
            this.totalProfiles = res?.data ?? 0;
            this.$isLoading(false);
            resolve(1);
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },

    getFilterGroups() {
      return new Promise((resolve, reject) => {
        this.$isLoading(true);
        let qs = "?publiclyVisible.equals=true&fks_=logo";

        var endpoint = this.generateUrl("groups", true);
        ApiService.get(this.$apiResource, endpoint + qs)
          .then((res) => {
            this.groups = res.data;
            this.totalGroups = this.groups.length;
            this.$isLoading(false);
            resolve(1);
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },

    getCommunity() {
      return new Promise((resolve, reject) => {
        this.$isLoading(true);
        this.currentPage = 1;

        let qs =
          "?profilePublic.equals=true&size=" +
          this.$options.static.visibleItemsPerPageCount +
          "&page=" +
          this.currentPage;

        if (this.groupId.length > 0) {
          qs += "&groupId.in=" + this.groupId;
        }

        if (this.keywords) {
          qs += "&fullName.contains=" + this.keywords;
        }

        var endpoint = this.generateUrl(
          this.selectedView == "grid" ? "users-page" : "users",
          true
        );
        ApiService.get(this.$apiResource, endpoint + qs)
          .then((res) => {
            if (this.selectedView == "grid") {
              this.pageCount = res.data.meta.pages;
              this.community = this.shuffle(res.data.content);
              this.displayViewMap = !this.community.every(
                (x) => !x.residenceCentroid
              );
            } else {
              this.community = this.shuffle(res.data);
              this.markers = [];
              this.community.forEach((usr) => {
                if (usr.residenceCentroid) {
                  this.markers.push({
                    user: usr,
                    position: this.pointToCoordinates(usr.residenceCentroid),
                  });
                }
              });
            }
            this.$isLoading(false);
            resolve(1);
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },

    loadMore($state) {
      setTimeout(() => {
        if (this.currentPage < this.pageCount) {
          this.currentPage += 1;

          return new Promise((resolve, reject) => {
            let qs =
              "?profilePublic.equals=true&size=" +
              this.$options.static.visibleItemsPerPageCount +
              "&page=" +
              this.currentPage;

            if (this.groupId.length > 0) {
              qs += "&groupId.in=" + this.groupId;
            }

            if (this.keywords) {
              qs += "&fullName.contains=" + this.keywords;
            }

            var endpoint = this.generateUrl("users-page", true);
            ApiService.get(this.$apiResource, endpoint + qs)
              .then((res) => {
                if (res.data.content != null) {
                  res.data.content = this.shuffle(res.data.content);
                  res.data.content.forEach((element) => {
                    this.community.push(element);
                  });
                  this.pageCount = res.data.meta.pages;
                }
                $state.loaded();
              })
              .catch(() => {
                reject(0);
              });
          });
        } else {
          $state.loaded();
        }
      });
    },

    selectGroup(groupId) {
      if (groupId == 0) {
        this.groupId = [];
      } else if (this.groupId.includes(groupId)) {
        this.groupId = this.groupId.filter((x) => x != groupId);
      } else {
        this.groupId.push(groupId);
      }
    },

    setView(value) {
      this.selectedView = value;
      this.getCommunity();
    },
    toggleInfoWindow(marker) {
      this.infoUser = marker.user;
      this.infoWindowPos = marker.position;
      this.infoWinOpen = true;
    },
    selectCluster(cluster) {
      if (cluster) {
        this.infoWinOpen = false;
        const clusterMarkers = cluster.getMarkers();

        if (this.maxZoom == this.$refs.mapRef.$mapObject.getZoom()) {
          this.clusterUsers = [];
          clusterMarkers.forEach((currentMarker) => {
            const usr = this.community.find(
              (usr) => usr.id == currentMarker.id
            );
            if (usr) {
              this.clusterUsers.push(usr);
            }
          });
          if (this.clusterUsers.length > 0) {
            this.$refs["communityClusterDetailModal"].open();
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
