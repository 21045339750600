<template>  
  <div class="card card-custom card-stretch" :style="!fromMap ? 'background-color: #ffffff00 !important; box-shadow: none !important;' : ''">
    <div class="card-body text-center p-3">

      <!--Avatar-->
      <router-link v-if="profilePublic" :to="{ name: 'userDetail', params: { id: id}}" target="_blank">
        <div class="avatar-container">
          <img :src="avatar" class="rounded-avatar"/>
          <img v-if="citizenship && userCitizenshipVisible" :src="citizenshipUri" class="avatar-citizenship" data-toggle="tooltip" v-b-tooltip.hover.top="citizenship" data-placement="top">
        </div>
      </router-link>
      <span v-else>
        <div class="avatar-container">
          <img :src="avatar" class="rounded-avatar"/>
          <img v-if="citizenship && userCitizenshipVisible" :src="citizenshipUri" class="avatar-citizenship" data-toggle="tooltip" v-b-tooltip.hover.top="citizenship" data-placement="top">
        </div>        
      </span>
      <!--end::Avatar-->

      <!--Name-->
      <div class="mt-3 text-dark-50 font-weight-bolder text-hover-primary font-size-h5 text-center">
        <span v-if="user.profilePublic == true"><router-link :to="{ name: 'userDetail', params: { id: id} }" target="_blank">{{name}}</router-link></span>
        <span v-else>Utente privato</span>
      </div>
      <!--end::Name-->

      <div class="d-flex flex-wrap justify-content-around" style="gap: 5px">
        <!--Assessor-->
        <div v-if="assessor != ''" class="mt-3 text-dark-50 font-weight-bolder text-center">
          <i class="fas fa-user-tag"></i> <span>{{assessor}}</span>
        </div>
        <!--end::Assessor-->


        <!--Expert-->
        <div v-if="expert != ''" class="mt-3 text-dark-50 font-weight-bolder text-center">
          <i class="fas fa-user-tag"></i> <span>{{expert}}</span>
        </div>
        <!--end::Expert-->

        <!--Residencecity-->
        <div v-if="residenceCity && !fromModal" class="mt-3 text-dark-50 font-weight-bolder text-center">
          <i class="fas fa-map-marker-alt"></i> <span>{{ residenceCity }}</span>
        </div>
        <!--Residencecity-->
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CommunityUserCard",
  props: ["user", "fromMap", "fromModal"],
  data() {
    return {
      id: 0,
      profilePublic: false,
      avatar: this.$imageOptions.defaultAvatarUrl,
      name: '',
      expert: '',
      assessor: '',
      citizenship: '',
      citizenshipUri: '',
      residenceCity: '',
      userCitizenshipVisible: false,  
    }
  },
  computed: {
    ...mapGetters([
      "getOrganization"
    ])
  },
  methods: {
    init() {
      this.id = this.user?.id ?? 0;
      this.profilePublic = this.user?.profilePublic ?? false;
      this.avatar = (this.user?.avatar?.uri && this.profilePublic) ? this.user?.avatar?.uri : this.$imageOptions.defaultAvatarUrl;
      this.name = (this.user?.name ?? '') + ' ' + (this.user?.surname ?? '');
      this.citizenship = (this.user.citizenship) ? this.user.citizenship.name : ''
      this.citizenshipUri = (this.user.citizenship) ? this.user.citizenship.flag.uri : ''
      this.residenceCity = this.user.residenceCity || '';

      this.userCitizenshipVisible = this.getOrganization?.data?.userCitizenshipVisible || false;

      if(this.user?.roles?.length > 0) {
        this.expert = this.user.roles.some(x => x.authority == "EXPERT") ? this.user.roles.find(x => x.authority == "EXPERT").name : ''
        this.assessor = this.user.roles.some(x => x.authority == "ASSESSOR") ? this.user.roles.find(x => x.authority == "ASSESSOR").name : ''
      }
    }
  },
  created() {
    this.init()
  },
  watch: {
    user() {
      this.init();
    }
  },
};
</script>

<style scoped>
  .avatar-container {
    position: relative;
    margin: auto;
    height: 85px;
    width: 85px;
  }
  .rounded-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  .avatar-citizenship {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 20px;
    object-fit: fill;    
  }
</style>
